// src/components/AboutSection.js

import React from 'react';
import { motion } from 'framer-motion';
import colors from '../colors';
import fonts from '../fonts';
import team from '../images/team.jpg';

const AboutSection = () => (
  <section id="about" className="py-20" style={{ backgroundColor: colors.background }}>
    <div className="container mx-auto px-4 md:px-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
        <motion.div
          initial={{ x: -50, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <h2
            className="text-4xl md:text-5xl font-bold mb-6"
            style={{ fontFamily: fonts.heading, color: colors.text }}
          >
            About <span className="text-accent">Us</span>
          </h2>
          <p className="text-lg mb-4" style={{ color: colors.lightText }}>
            Montaigne is a Business Architecture and Go To Market Strategy Business. We are at the forefront of blending fundamental thinking with acute business strategies. Our mission is to innovate today and inspire tomorrow, solving complex challenges and shaping the future of businesses.
          </p>
          <p className="text-lg" style={{ color: colors.lightText }}>
            With a track record of transforming challenges into opportunities, we are the ideal partner for entities looking to grow market share in their industry.
          </p>
        </motion.div>
        <motion.div
          className="rounded-lg overflow-hidden shadow-xl"
          initial={{ x: 50, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <img
            src={team}
            alt="Montaigne Team"
            className="w-full h-auto"
          />
        </motion.div>
      </div>
    </div>
  </section>
);

export default AboutSection;