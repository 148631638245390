// src/components/IndustriesSection.js

import React from 'react';
import { motion } from 'framer-motion';
import { Leaf, Home } from 'lucide-react';
import colors from '../colors';
import fonts from '../fonts';

const industries = [
  {
    title: 'Luxury Eco Tourism',
    icon: Leaf,
    link: '/luxury-eco-tourism-strategy',
  },
  {
    title: 'Luxury Real Estate',
    icon: Home,
    link: '/luxury-real-estate-strategy',
  },
];

const IndustriesSection = () => (
  <section id="industries" className="py-20" style={{ backgroundColor: colors.secondary }}>
    <div className="container mx-auto px-4 md:px-8">
      <h2
        className="text-4xl md:text-5xl font-bold mb-12 text-center"
        style={{ fontFamily: fonts.heading, color: colors.text }}
      >
        Our <span className="text-accent">Industries</span>
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {industries.map((industry, index) => (
          <motion.a
            key={index}
            href={industry.link}
            className="block"
            whileHover={{ scale: 1.05 }}
            transition={{ type: 'spring', stiffness: 300 }}
          >
            <div className="bg-background p-6 rounded-lg flex items-center shadow-lg">
              <industry.icon className="h-12 w-12 mr-4 text-accent" />
              <h3
                className="text-2xl font-semibold"
                style={{ fontFamily: fonts.heading, color: colors.text }}
              >
                {industry.title}
              </h3>
            </div>
          </motion.a>
        ))}
      </div>
    </div>
  </section>
);

export default IndustriesSection;