// src/components/ServicesSection.js

import React from 'react';
import { motion } from 'framer-motion';
import { Rocket, Building, TrendingUp } from 'lucide-react';
import colors from '../colors';
import fonts from '../fonts';

const services = [
  {
    title: 'Business Architecture',
    icon: Building,
    description: 'Designing and optimizing robust business structures for sustainable growth and efficiency.',
  },
  {
    title: 'Go-to-Market Strategy',
    icon: Rocket,
    description: 'Crafting and implementing comprehensive strategies for successful market entry and product launches.',
  },
  {
    title: 'Growth Scaling',
    icon: TrendingUp,
    description: 'Leveraging our strongest forte to exponentially grow your business from 1 to 100.',
  },
];

const ServicesSection = () => (
  <section id="services" className="py-20" style={{ backgroundColor: colors.background }}>
    <div className="container mx-auto px-4 md:px-8">
      <h2
        className="text-4xl md:text-5xl font-bold mb-12 text-center"
        style={{ fontFamily: fonts.heading, color: colors.text }}
      >
        Our <span className="text-accent">Services</span>
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {services.map((service, index) => (
          <motion.div
            key={index}
            className="bg-secondary p-6 rounded-lg shadow-lg"
            whileHover={{ scale: 1.05 }}
            transition={{ type: 'spring', stiffness: 300 }}
          >
            <service.icon className="h-12 w-12 mb-4 text-accent" />
            <h3
              className="text-2xl font-semibold mb-2"
              style={{ fontFamily: fonts.heading, color: colors.text }}
            >
              {service.title}
            </h3>
            <p style={{ color: colors.lightText }}>
              {service.description}
            </p>
          </motion.div>
        ))}
      </div>
    </div>
  </section>
);

export default ServicesSection;