// src/components/HeroSection.js

import React from 'react';
import { motion } from 'framer-motion';
import { Link as ScrollLink } from 'react-scroll';
import { ChevronRight } from 'lucide-react';
import colors from '../colors';
import fonts from '../fonts';

const HeroSection = ({ title = "", subtitle, ctaText, ctaLink }) => (
  <section id="hero" className="pt-32 pb-20 bg-gradient-to-br from-background via-secondary to-accent text-text">
    <div className="container mx-auto px-4 md:px-8">
      <div className="text-center">
        <motion.h1
          className="text-5xl md:text-7xl font-bold mb-6"
          style={{ fontFamily: fonts.heading, color: colors.primary }}
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {title.split(' ').map((word, index) => (
            <span key={index} className={index % 2 ? "text-accent" : ""}>
              {word}{' '}
            </span>
          ))}
        </motion.h1>
        <motion.p
          className="text-xl md:text-2xl mb-8 max-w-3xl mx-auto"
          style={{ color: colors.lightText }}
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          {subtitle}
        </motion.p>
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <ScrollLink
            to={ctaLink}
            smooth={true}
            duration={500}
            className="inline-flex items-center bg-accent text-buttonText px-8 py-3 rounded-full text-lg font-semibold transition duration-300 relative overflow-hidden group"
          >
            <span className="relative z-10">{ctaText}</span>
            <ChevronRight className="inline-block ml-2 relative z-10" />
            <motion.div
              className="absolute inset-0 bg-gradient-to-r from-accent to-darkAccent"
              initial={{ x: '-100%' }}
              whileHover={{ x: '0%' }}
              transition={{ duration: 0.3 }}
            />
          </ScrollLink>
        </motion.div>
      </div>
    </div>
  </section>
);

export default HeroSection;