// src/pages/LuxuryRealEstatePage.js

import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import Newsletter from '../components/Newsletter';
import ContactForm from '../components/ContactForm';
import CaseStudy from '../components/CaseStudy';
import { TrendingUp, Globe, Users, Shield, Compass, Sun, Briefcase, Leaf, Smartphone, Heart, Palette, Brain, Lock, MapPin, Handshake } from 'lucide-react';
import colors from '../colors';
import fonts from '../fonts';

const Section = ({ children, className, id, bgColor = 'bg-background' }) => (
  <section id={id} className={`py-20 ${className} ${bgColor}`}>
    <div className="container mx-auto px-4 md:px-8">{children}</div>
  </section>
);

const LuxuryRealEstatePage = () => {
  return (
    <>
      <Helmet>
        <title>Luxury Real Estate Strategy | Montaigne</title>
        <meta name="description" content="Explore Montaigne's innovative strategies for luxury real estate, redefining opulence in property development and marketing in a rapidly evolving global market." />
        <meta property="og:title" content="Luxury Real Estate Strategy | Montaigne" />
        <meta property="og:description" content="Discover cutting-edge strategies for luxury real estate, tailored for high-net-worth individuals and emerging markets. Montaigne: Your partner in navigating the evolving landscape of premium properties." />
        <meta property="og:image" content="/images/luxury-real-estate-og.jpg" />
        <meta property="og:url" content="https://www.montaigne.co/luxury-real-estate-strategy" />
        <link rel="canonical" href="https://www.montaigne.co/luxury-real-estate-strategy" />
      </Helmet>
      <div className="bg-background text-text min-h-screen">
        <Header />
        <main>
          <HeroSection 
            title="Elevating Luxury in Real Estate"
            subtitle="Crafting unparalleled experiences in high-end property development and marketing for a global elite clientele."
            ctaText="Explore Market Trends"
            ctaLink="trends"
          />
          <MarketTrendsSection />
          <ClientDemandsSection />
          <ActionableInsightsSection />
          <CaseStudySection />
          <NewsletterSection />
          <ContactSection />
        </main>
        <Footer />
      </div>
    </>
  );
};

const MarketTrendsSection = () => {
  const trends = [
    {
      icon: Globe,
      title: "Global Surge in Ultra-Luxury",
      description: "Sales of $10M+ homes have skyrocketed, with Palm Beach leading at a 44% increase, driven by substantial wealth creation among the ultra-rich.",
    },
    {
      icon: Compass,
      title: "Emerging Luxury Hotspots",
      description: "Cities like Dubai are transforming into new luxury epicenters, attracting wealthy buyers with favorable tax and regulatory policies.",
    },
    {
      icon: Sun,
      title: "Resilient Asian Markets",
      description: "Mumbai and Delhi have secured top positions globally with 13% and 10.6% annual increases in prime residential property prices, respectively.",
    },
    {
      icon: Briefcase,
      title: "Strategic Brand Expansions",
      description: "Prestigious brands like Christie's International Real Estate are expanding into thriving markets such as Singapore, intensifying competition.",
    },
    {
      icon: Shield,
      title: "Blockchain Integration",
      description: "Emerging markets like Ras Al Khaimah are adopting blockchain technology for property transactions, appealing to tech-savvy investors.",
    },
    {
      icon: Users,
      title: "Shift in Buyer Demographics",
      description: "A new wave of younger, tech-savvy ultra-high-net-worth individuals is reshaping demand for luxury properties globally.",
    },
  ];

  return (
    <Section id="trends" className="py-20 bg-secondary">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl md:text-5xl font-bold mb-12 text-center text-primary">
          Market Trends Shaping <span className="text-accent">Luxury Real Estate</span>
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {trends.map((trend, index) => (
            <motion.div
              key={index}
              className="bg-background p-6 rounded-lg shadow-lg"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <trend.icon className="w-12 h-12 text-accent mb-4" />
              <h3 className="text-2xl font-semibold mb-4 text-primary">{trend.title}</h3>
              <p className="text-text">{trend.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </Section>
  );
};

const ClientDemandsSection = () => {
  const demands = [
    {
      title: "Sustainable Luxury",
      description: "High-net-worth individuals are increasingly seeking eco-friendly and sustainable luxury properties.",
      icon: Leaf,
    },
    {
      title: "Tech-Integrated Living",
      description: "Smart home technologies and advanced security systems are becoming standard expectations in luxury real estate.",
      icon: Smartphone,
    },
    {
      title: "Wellness-Focused Amenities",
      description: "Properties with spa facilities, meditation rooms, and fitness centers are in high demand.",
      icon: Heart,
    },
    {
      title: "Private Outdoor Spaces",
      description: "Expansive terraces, private gardens, and rooftop retreats are highly sought after in urban luxury properties.",
      icon: Sun,
    },
    {
      title: "Bespoke Designs",
      description: "Customization options and unique architectural features are increasingly important to luxury buyers.",
      icon: Palette,
    },
    {
      title: "Investment Potential",
      description: "Properties in emerging markets or with strong rental yield potential are attracting savvy investors.",
      icon: TrendingUp,
    }
  ];

  return (
    <Section id="demands" className="py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl md:text-5xl font-bold mb-12 text-center text-primary">
          What Your <span className="text-accent">Clients Are Demanding</span>
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {demands.map((demand, index) => (
            <motion.div
              key={index}
              className="bg-secondary p-6 rounded-lg shadow-lg"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <demand.icon className="w-12 h-12 text-accent mb-4" />
              <h3 className="text-2xl font-semibold mb-4 text-primary">{demand.title}</h3>
              <p className="text-text">{demand.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </Section>
  );
};

const ActionableInsightsSection = () => {
  const insights = [
    {
      title: "Leverage AI for Personalized Marketing",
      description: "Utilize AI-driven analytics to create hyper-personalized marketing campaigns targeting ultra-high-net-worth individuals.",
      icon: Brain,
      benefit: "Enhanced Client Acquisition"
    },
    {
      title: "Embrace Sustainable Luxury",
      description: "Incorporate eco-friendly features and sustainable materials in luxury developments to appeal to environmentally conscious buyers.",
      icon: Leaf,
      benefit: "Market Differentiation"
    },
    {
      title: "Develop Virtual Reality Experiences",
      description: "Create immersive VR property tours to cater to international buyers and streamline the decision-making process.",
      icon: Smartphone,
      benefit: "Global Reach Expansion"
    },
    {
      title: "Forge Strategic Partnerships",
      description: "Collaborate with luxury brands and high-end service providers to offer unique lifestyle packages with property purchases.",
      icon: Handshake,
      benefit: "Value Addition"
    },
    {
      title: "Implement Blockchain for Transactions",
      description: "Adopt blockchain technology for secure, transparent, and efficient property transactions to attract tech-savvy investors.",
      icon: Lock,
      benefit: "Increased Trust and Efficiency"
    },
    {
      title: "Focus on Emerging Luxury Markets",
      description: "Expand presence in rapidly growing luxury markets like Dubai and Singapore to tap into new wealth centers.",
      icon: MapPin,
      benefit: "Market Diversification"
    }
  ];

  return (
    <Section id="insights" className="py-20 bg-secondary">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl md:text-5xl font-bold mb-12 text-center text-primary">
          Your Next <span className="text-accent">Power Moves</span>
        </h2>
        <div className="space-y-8">
          {insights.map((insight, index) => (
            <motion.div
              key={index}
              className="bg-background p-6 rounded-lg shadow-lg"
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
            >
              <div className="flex items-center space-x-4 mb-4">
                <insight.icon className="w-12 h-12 text-accent" />
                <h3 className="text-2xl font-bold text-primary">{insight.title}</h3>
              </div>
              <p className="text-lg mb-2 text-text">{insight.description}</p>
              <p className="text-sm font-semibold text-accent">
                Benefit: {insight.benefit}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </Section>
  );
};

const CaseStudySection = () => (
  <Section id="case-study" className="py-20 bg-secondary">
    <div className="container mx-auto px-4">
      <h2 className="text-4xl md:text-5xl font-bold mb-12 text-center" style={{ color: colors.text, fontFamily: fonts.heading }}>
        Success <span className="text-accent">Story</span>
      </h2>
      <CaseStudy />
    </div>
  </Section>
);

const NewsletterSection = () => (
  <Section id="newsletter" className="bg-texture section-gradient-2">
    <h2 className="text-4xl md:text-5xl font-bold mb-12 text-center" style={{ fontFamily: fonts.heading }}>
      <span style={{ color: colors.primary }}>Stay</span>{' '}
      <span style={{ color: colors.accent }}>Informed</span>
    </h2>
    <div className="max-w-lg mx-auto">
      <Newsletter />
    </div>
  </Section>
);

const ContactSection = () => (
  <Section id="contact">
    <h2 className="text-4xl md:text-5xl font-bold mb-12 text-center text-primary">
      Elevate Your <span className="text-accent">Real Estate Strategy</span>
    </h2>
    <div className="max-w-lg mx-auto">
      <div className="bg-secondary p-8 rounded-lg shadow-lg">
        <ContactForm />
      </div>
    </div>
  </Section>
);

export default LuxuryRealEstatePage;