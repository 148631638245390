// src/index.js

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// Import Font Awesome CSS
import '@fortawesome/fontawesome-free/css/all.min.css';

// Import Tailwind CSS
import './index.css'; // This file will include Tailwind directives

// Import any other global CSS files here

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-K66Z2NL3',
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById('root'));
