// src/colors.js

const colors = {
  primary: '#FFFFFF',
  secondary: '#1A1A1A',
  text: '#E0E0E0',
  lightText: '#BDBDBD',
  background: '#0D0D0D',
  accent: '#00B3B3',
  accentLight: '#00E6E6',
  accentDark: '#008080',
  header: '#141E30',
  headerGradient: 'linear-gradient(to right, #141E30, #243B55)',
  sectionBackground1: '#0F2027',
  sectionBackground2: '#203A43',
  sectionBackground3: '#2C5364',
  buttonText: '#FFFFFF',
  error: '#FF5252',
  success: '#4CAF50',
  warning: '#FFC107',
};

export default colors;