// src/pages/PrivacyPolicyPage.js

import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Footer from '../components/Footer';
import colors from '../colors';
import fonts from '../fonts';

const PrivacyPolicyPage = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Montaigne</title>
        <meta
          name="description"
          content="We value your privacy and ensure that your personal data is handled with care and security."
        />
      </Helmet>
      <div className="bg-background text-text" style={{ fontFamily: fonts.primary }}>
        <Header />
        <main className="container mx-auto px-4 py-16" style={{ paddingTop: '80px' }}>

          <h1
            className="text-4xl font-bold mb-8 text-center"
            style={{ fontFamily: fonts.heading, color: colors.text }}
          >
            Privacy Policy
          </h1>

          <section className="mb-8">
            <p className="text-lg mb-4" style={{ color: colors.lightText }}>
              At Montaigne, we value your privacy and are committed to protecting
              your personal data. This privacy policy outlines how we handle any
              information you may provide when using our website.
            </p>
          </section>

          <section className="mb-8">
            <h2
              className="text-2xl font-semibold mb-4"
              style={{ fontFamily: fonts.heading, color: colors.text }}
            >
              Data Collection
            </h2>
            <p className="text-lg mb-4" style={{ color: colors.lightText }}>
              We do not collect any personal or private data when you simply
              browse our website. Your privacy is important to us, and we ensure
              that your experience on our site remains secure and anonymous.
            </p>
          </section>

          <section className="mb-8">
            <h2
              className="text-2xl font-semibold mb-4"
              style={{ fontFamily: fonts.heading, color: colors.text }}
            >
              Data Provided for Ads and Forms
            </h2>
            <p className="text-lg mb-4" style={{ color: colors.lightText }}>
              If you choose to fill out a form on our website or interact with
              our ads, we may collect your name and email address. This
              information is stored securely and used solely for the purpose of
              contacting you regarding your requirements or to provide a better
              customer experience. We do not share or sell this information to
              any third parties.
            </p>
          </section>

          <section className="mb-8">
            <h2
              className="text-2xl font-semibold mb-4"
              style={{ fontFamily: fonts.heading, color: colors.text }}
            >
              Use of Data
            </h2>
            <p className="text-lg mb-4" style={{ color: colors.lightText }}>
              The information you provide is only used to respond to your
              inquiries, enhance our services, or fulfill your requests. We do
              not use your data for any other purposes beyond your direct
              engagement with us.
            </p>
          </section>

          <section className="mb-8">
            <h2
              className="text-2xl font-semibold mb-4"
              style={{ fontFamily: fonts.heading, color: colors.text }}
            >
              Data Security
            </h2>
            <p className="text-lg mb-4" style={{ color: colors.lightText }}>
              We prioritize the security of your personal data and implement
              industry-standard measures to protect it from unauthorized access,
              misuse, or disclosure. We store your information in a secure
              environment and limit access to only those employees who need it
              for specific purposes.
            </p>
          </section>

          <section className="mb-8">
            <h2
              className="text-2xl font-semibold mb-4"
              style={{ fontFamily: fonts.heading, color: colors.text }}
            >
              Contact Us
            </h2>
            <p className="text-lg" style={{ color: colors.lightText }}>
              If you have any questions about our privacy practices or how your
              data is handled, please feel free to contact us at{' '}
              <a href="mailto:info@montaigne.co" className="text-accent">
                info@montaigne.co
              </a>.
            </p>
          </section>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default PrivacyPolicyPage;
