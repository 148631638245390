// src/components/Footer.js

import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedin, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import fonts from '../fonts';
import logo from '../images/mlw.png';

const Footer = () => {
  return (
    <footer className="bg-secondary text-primary py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="flex flex-col items-center md:items-start">
            <img src={logo} alt="Montaigne Logo" className="h-16 w-auto mb-4" />
            <p className="text-lightText text-center md:text-left">Innovate Today, Inspire Tomorrow</p>
          </div>
          <div className="flex flex-col items-center md:items-start">
            <h4 className="text-xl font-semibold mb-4" style={{ fontFamily: fonts.heading }}>Quick Links</h4>
            <nav>
              <ul className="space-y-2">
                <li><Link to="/" className="text-lightText hover:text-accent transition-colors">Home</Link></li>
                <li><Link to="/#about" className="text-lightText hover:text-accent transition-colors">About</Link></li>
                <li><Link to="/#contact" className="text-lightText hover:text-accent transition-colors">Contact</Link></li>
                <li><Link to="/privacy-policy" className="text-lightText hover:text-accent transition-colors">Privacy Policy</Link></li>
              </ul>
            </nav>
          </div>
          <div className="flex flex-col items-center md:items-start">
            <h4 className="text-xl font-semibold mb-4" style={{ fontFamily: fonts.heading }}>Contact Us</h4>
            <ul className="space-y-2">
              <li className="flex items-center">
                <FontAwesomeIcon icon={faEnvelope} className="mr-2 text-accent" />
                <a href="mailto:info@montaigne.co" className="text-lightText hover:text-accent transition-colors">info@montaigne.co</a>
              </li>
              <li className="flex items-center">
                <FontAwesomeIcon icon={faPhone} className="mr-2 text-accent" />
                <a href="tel:+919700500900" className="text-lightText hover:text-accent transition-colors">+91 9700500900</a>
              </li>
              <li className="flex items-center">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2 text-accent" />
                <span className="text-lightText">Hyderabad, India</span>
              </li>
            </ul>
            <div className="flex space-x-4 mt-4">
              <a href="https://twitter.com/montaigneco" target="_blank" rel="noopener noreferrer" className="text-lightText hover:text-accent transition-colors">
                <FontAwesomeIcon icon={faTwitter} size="lg" />
              </a>
              <a href="https://www.linkedin.com/company/montaigneco" target="_blank" rel="noopener noreferrer" className="text-lightText hover:text-accent transition-colors">
                <FontAwesomeIcon icon={faLinkedin} size="lg" />
              </a>
              <a href="https://www.instagram.com/montaigneco" target="_blank" rel="noopener noreferrer" className="text-lightText hover:text-accent transition-colors">
                <FontAwesomeIcon icon={faInstagram} size="lg" />
              </a>
              <a href="https://www.facebook.com/montaigneco" target="_blank" rel="noopener noreferrer" className="text-lightText hover:text-accent transition-colors">
                <FontAwesomeIcon icon={faFacebook} size="lg" />
              </a>
            </div>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-700 text-center">
          <p className="text-lightText">&copy; {new Date().getFullYear()} Montaigne Smart Business Solutions. All rights Reserved. Designed and Brewed at ❤️ Montaigne Labs</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;