// src/App.js

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TagManager from 'react-gtm-module';
import HomePage from './pages/HomePage';
import LuxuryEcoTourismPage from './pages/LuxuryEcoTourismPage';
import LuxuryRealEstatePage from './pages/LuxuryRealEstatePage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import SchemaMarkup from './components/SchemaMarkup';

const tagManagerArgs = {
  gtmId: 'GTM-K66Z2NL3'
};

function App() {
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <Router>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#0D0D0D" />
        <link rel="manifest" href="/manifest.json" />
      </Helmet>
      <SchemaMarkup />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/luxury-eco-tourism-strategy" element={<LuxuryEcoTourismPage />} />
        <Route path="/luxury-real-estate-strategy" element={<LuxuryRealEstatePage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      </Routes>
    </Router>
  );
}

export default App;