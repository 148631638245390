// src/components/CaseStudy.js

import React from 'react';
import { motion } from 'framer-motion';
import colors from '../colors';
import fonts from '../fonts';

const luxofyCaseStudy = {
  title: "Luxofy: A Montaigne Success Story in Luxury Real Estate",
  description: "In a span of just 5 months, Montaigne transformed Luxofy into India's fastest-growing Luxury Second Home Expert. Our comprehensive approach reshaped every facet of the business, from foundational architecture to market execution.",
  achievements: [
    {
      title: "Audience-Centric Business Design",
      points: [
        "Tailored organizational structure and processes to meet UHNWI and HNWI expectations",
        "Reduced transaction completion time by 40% while enhancing client satisfaction"
      ]
    },
    {
      title: "Strategic Market Penetration",
      points: [
        "Developed multi-channel approach leveraging exclusive networks and events",
        "Achieved 400% faster market penetration compared to industry average"
      ]
    },
    {
      title: "Bridging Product and People",
      points: [
        "Created immersive property showcases that resonated with elite clientele",
        "Increased remote buyer confidence by 250% through innovative presentation methods"
      ]
    },
    {
      title: "Operational Excellence",
      points: [
        "Standardized luxury client journey from first contact to post-sale support",
        "Implemented rigorous quality control for property curation, elevating brand prestige"
      ]
    },
    {
      title: "Data-Informed Decision Making",
      points: [
        "Enabled agile pricing strategies, optimizing profitability and sales velocity",
        "Achieved 98% accuracy in luxury market trend predictions"
      ]
    }
  ],
  impact: "Our deep understanding of UHNWI and HNWI preferences, combined with seamless integration of client insights into business strategy, propelled Luxofy to the forefront of the ultra-luxury real estate market. This case study exemplifies our ability to translate luxury offerings into compelling value propositions, setting new industry standards in the process."
};

const CaseStudy = () => {
  return (
    <motion.div
      className="bg-background p-8 rounded-lg shadow-xl mb-12"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h3 className="text-2xl font-semibold mb-4 text-primary" style={{ fontFamily: fonts.heading, color: colors.text }}>
        {luxofyCaseStudy.title}
      </h3>
      <p className="text-lg mb-6 text-text" style={{ color: colors.lightText }}>
        {luxofyCaseStudy.description}
      </p>
      <h4 className="text-xl font-semibold mb-4 text-primary" style={{ fontFamily: fonts.heading, color: colors.text }}>
        Key Achievements:
      </h4>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        {luxofyCaseStudy.achievements.map((achievement, idx) => (
          <div key={idx} className="bg-secondary p-4 rounded-lg">
            <h5 className="text-lg font-semibold mb-2 text-primary" style={{ fontFamily: fonts.heading, color: colors.text }}>
              {achievement.title}
            </h5>
            <ul className="list-disc list-inside">
              {achievement.points.map((point, pointIdx) => (
                <li key={pointIdx} className="text-lightText" style={{ color: colors.lightText }}>{point}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <h4 className="text-xl font-semibold mb-4 text-primary" style={{ fontFamily: fonts.heading, color: colors.text }}>
        The Montaigne Impact:
      </h4>
      <p className="text-lg text-text" style={{ color: colors.lightText }}>
        {luxofyCaseStudy.impact}
      </p>
    </motion.div>
  );
};

export default CaseStudy;