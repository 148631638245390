// src/components/Newsletter.js

import React from 'react';
import colors from '../colors';
import fonts from '../fonts';

const Newsletter = () => {
  return (
    <div className="bg-secondary p-8 rounded-lg shadow-lg">
      <h3
        className="text-2xl md:text-3xl font-bold mb-4 text-center"
        style={{ fontFamily: fonts.heading, color: colors.text }}
      >
        Subscribe to Our Newsletter
      </h3>
      <p
        className="text-lg mb-6 text-center"
        style={{ color: colors.lightText }}
      >
        Get weekly business architecture and 'Disruptive' Discussions
        delivered to your inbox.
      </p>
      <a
        href="https://montaigne.beehiiv.com/subscribe"
        target="_blank"
        rel="noopener noreferrer"
        className="block w-full text-buttonText px-4 py-2 rounded-md hover:opacity-90 transition duration-300 text-center font-semibold"
        style={{ fontFamily: fonts.primary, background: colors.accent }}
      >
        Subscribe Now
      </a>
    </div>
  );
};

export default Newsletter;