// src/components/JourneyVideoSection.js

import React from 'react';
import { motion } from 'framer-motion';
import colors from '../colors';
import fonts from '../fonts';

const JourneyVideoSection = () => (
  <section id="journey" className="py-20 bg-secondary">
    <div className="container mx-auto px-4 md:px-8">
      <motion.h2
        className="text-4xl md:text-5xl font-bold mb-12 text-center"
        style={{ fontFamily: fonts.heading, color: colors.text }}
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5 }}
      >
        Our <span className="text-accent">Journey</span>
      </motion.h2>
    </div>
    <div className="relative w-full" style={{ paddingTop: '56.25%' }}>
      <iframe
        src="https://www.youtube.com/embed/BmFgmXAOJLM?autoplay=1&mute=1&loop=1&playlist=BmFgmXAOJLM&controls=0&showinfo=0&rel=0&modestbranding=1&iv_load_policy=3&color=white"
        title="Montaigne Journey"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="absolute top-0 left-0 w-full h-full"
      ></iframe>
    </div>
    <div className="container mx-auto px-4 md:px-8">
      <motion.p
        className="text-xl md:text-2xl mt-8 text-center max-w-3xl mx-auto"
        style={{ color: colors.lightText }}
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        Experience the evolution of Montaigne and our commitment to innovation.
      </motion.p>
    </div>
  </section>
);

export default JourneyVideoSection;