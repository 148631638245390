// src/components/ScrollSection.js

import React, { useEffect, useRef } from 'react';
import colors from '../colors';
import fonts from '../fonts';

const ScrollSection = ({ items, title, subtitle, showTitles = false }) => {
  const scrollRef = useRef(null);

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      const scrollAnimation = () => {
        scrollElement.scrollLeft += 1;
        if (scrollElement.scrollLeft >= (scrollElement.scrollWidth - scrollElement.clientWidth)) {
          scrollElement.scrollLeft = 0;
        }
      };

      const animationId = setInterval(scrollAnimation, 50);

      return () => clearInterval(animationId);
    }
  }, []);

  return (
    <div className="bg-secondary py-20">
      <div className="container mx-auto px-4 md:px-8">
        <h2 className="text-4xl md:text-5xl font-bold mb-12 text-center" style={{ fontFamily: fonts.heading, color: colors.text }}>
          {title} <span className="text-accent">{subtitle}</span>
        </h2>
        <div className="overflow-hidden">
          <div 
            ref={scrollRef}
            className="flex space-x-8 overflow-x-scroll scrollbar-hide"
            style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
          >
            {items.concat(items).map((item, index) => (
              <div 
                key={index} 
                className="flex-shrink-0 w-64 flex flex-col items-center justify-center"
                style={{ height: '180px' }}
              >
                <div className="h-40 w-40 flex items-center justify-center">
                  <img
                    src={item.logo || item.image}
                    alt={item.name || item.title}
                    className="max-h-full max-w-full object-contain"
                  />
                </div>
                {showTitles && (
                  <p
                    className="text-sm text-center text-lightText w-full mt-2"
                    style={{
                      fontFamily: fonts.primary,
                      color: colors.lightText,
                      overflow: 'hidden',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                      lineHeight: '1.2em',
                      maxHeight: '2.4em'
                    }}
                  >
                    {item.name || item.title}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrollSection;

