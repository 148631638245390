// src/components/SchemaMarkup.js

import React from 'react';
import { Helmet } from 'react-helmet';

const SchemaMarkup = () => {
  const companySchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Montaigne Smart Business Solutions",
    "url": "https://www.montaigne.co",
    "logo": "https://www.montaigne.co/logo.png",
    "description": "Montaigne Smart Business Solutions blends advanced AI technologies with acute business strategies to innovate today and inspire tomorrow.",
    "founder": {
      "@type": "Person",
      "name": "Rohith Sampathi"
    },
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Hyderabad",
      "addressCountry": "India"
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+919700500900",
      "contactType": "customer service"
    }
  };

  const productSchema = {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": "Market Unwinded",
    "description": "An AI-powered Knowledge Base and Market Universe Reference tool for strategic planning and decision-making.",
    "brand": {
      "@type": "Brand",
      "name": "Montaigne"
    }
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(companySchema)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(productSchema)}
      </script>
    </Helmet>
  );
};

export default SchemaMarkup;