// src/components/TestimonialsSection.js

import React from 'react';
import { motion } from 'framer-motion';
import { Star } from 'lucide-react';
import colors from '../colors';
import fonts from '../fonts';

const testimonials = [
  {
    name: 'Vishal Bhamare',
    company: 'Luxofy Realty',
    text: 'Montaigne is working as our strategy partner and has helped us grow to India\'s Fastest Growing Second Home expert. We are happy with their ability to understand our niche product and place it effectively in front our HNI target audience. We look forward to more growth hacks from them.',
  },
  {
    name: 'Awais',
    company: 'Tichi Auto',
    text: "Thank you Montaigne for growing our automotive business in Canada.  We count on your expertise for all our Go To Market Strategies — you make sure we are seen and heard in our marketplace. We continue to work with you and have implemented all of your recommendations and are confident in your abilities, your team is amazing and highly proficient in what you do.  Looking forward to many years of partnership together!"
  },
  {
    name: 'Vishal Raheja',
    company: 'InvestoXpert',
    text: "Montaigne has helped us build a strong bridge between our product and target audience with their go to market approaches and robust tech guidance",
  },
];

const TestimonialsSection = () => (
  <section id="testimonials" className="py-20" style={{ backgroundColor: colors.background }}>
    <div className="container mx-auto px-4 md:px-8">
      <h2
        className="text-4xl md:text-5xl font-bold mb-12 text-center"
        style={{ fontFamily: fonts.heading, color: colors.text }}
      >
        Client <span className="text-accent">Testimonials</span>
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {testimonials.map((testimonial, index) => (
          <motion.div
            key={index}
            className="bg-secondary p-6 rounded-lg shadow-lg"
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <Star className="h-8 w-8 mb-4 text-accent" />
            <p className="mb-4" style={{ color: colors.lightText }}>
              {testimonial.text}
            </p>
            <p className="font-semibold" style={{ color: colors.text }}>
              {testimonial.name}
            </p>
            <p className="text-accent">{testimonial.company}</p>
          </motion.div>
        ))}
      </div>
    </div>
  </section>
);

export default TestimonialsSection;